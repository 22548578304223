import React from 'react'
import styles from "./styles.module.scss"

const Main = props => {
    return (
        <main className={styles.main}>
            {props.children}
        </main>
    )
}

export default Main
